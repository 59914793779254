<template>
  <div class="pd-20">
    <p>
      <br/>
    </p>
    <div class="index-module_header_3qjZG">
      <div class="index-module_title_1s0gC">
        <h1 id="article-title" class="index-module_articleTitle_2xHPX" style="font-size:36px;color:#262626;">
          下载安装？
        </h1>
      </div>
    </div>
    <div>
      <div class="yuque-doc-content">
        <div class="lake-engine-view lake-typography-classic">
          <h1 id="AQAJz" style="font-size:28px;">
            ANKI探索版版下载：
          </h1>
          <p style="font-size:15px;color:#404040;">
            MAC版：<a href="https://www.ankichinas.cn/" target="_blank">https://www.ankichinas.cn/</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            Windows：<a href="https://www.ankichinas.cn/" target="_blank">https://www.ankichinas.cn/</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            Android：<a href="https://www.ankichinas.cn/" target="_blank">https://www.ankichinas.cn/</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            iOS ：<a href="https://apps.apple.com/cn/app/anki%E8%AE%B0%E5%BF%86%E5%8D%A1/id1540528340" target="_blank">https://apps.apple.com/cn/app/Suji记忆卡/id1540528340</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <h1 id="JoCgB" style="font-size:28px;">
            Android端历史版本（探索版）：
          </h1>
          <p style="font-size:15px;color:#404040;">
            3.00.0(新界面)：<a href="https://jwmc3ydpuq.feishu.cn/file/boxcnwGERcVzsvWhlH7oYl3H3nb" target="_blank">https://jwmc3ydpuq.feishu.cn/file/boxcnwGERcVzsvWhlH7oYl3H3nb</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            2.14.6(老界面)：<a href="https://jwmc3ydpuq.feishu.cn/file/boxcnc92KwpZ2dMYLSwT84lNeEg" target="_blank">https://jwmc3ydpuq.feishu.cn/file/boxcnc92KwpZ2dMYLSwT84lNeEg</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            2.14.3(老界面)：<a href="https://jwmc3ydpuq.feishu.cn/file/boxcnGqZ5TZTmR2GwDBkmoNzb2e" target="_blank">https://jwmc3ydpuq.feishu.cn/file/boxcnGqZ5TZTmR2GwDBkmoNzb2e</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            2.13.4（老界面）：<a href="https://jwmc3ydpuq.feishu.cn/file/boxcnaQEnUV57owSnh5JPFUuklJ" target="_blank">https://jwmc3ydpuq.feishu.cn/file/boxcnaQEnUV57owSnh5JPFUuklJ</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            2.9.5（部分卡牌需要此版本支持）:<a href="https://jwmc3ydpuq.feishu.cn/file/boxcn6OHCrJYQTEkViS5oipWe0c" target="_blank">https://jwmc3ydpuq.feishu.cn/file/boxcn6OHCrJYQTEkViS5oipWe0c</a>
          </p>
          <h1 id="JwuHZ" style="font-size:28px;">
            ANKI官方版下载
          </h1>
          <p style="font-size:15px;color:#404040;">
            MAC版：<a href="https://jwmc3ydpuq.feishu.cn/file/boxcnsmRGdFf1qnU0eOp6BC00Yx" target="_blank">https://jwmc3ydpuq.feishu.cn/file/boxcnsmRGdFf1qnU0eOp6BC00Yx</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            Windows：<a href="https://jwmc3ydpuq.feishu.cn/file/boxcn3wr8f0SjMyiSdgVHwQttEh" target="_blank">https://jwmc3ydpuq.feishu.cn/file/boxcn3wr8f0SjMyiSdgVHwQttEh</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            Android：<a href="https://jwmc3ydpuq.feishu.cn/file/boxcnH9O2cmJXmJQ8F8JWaEhGwQ" target="_blank">https://jwmc3ydpuq.feishu.cn/file/boxcnH9O2cmJXmJQ8F8JWaEhGwQ</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            iOS(收费) ：<a href="https://apps.apple.com/cn/app/id373493387" target="_blank">https://apps.apple.com/cn/app/id373493387</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <h1 id="pLiRr" style="font-size:28px;">
            ANKI支持哪些平台？
          </h1>
          <p style="font-size:15px;color:#404040;">
            ANKI是覆盖全平台的，支持MAC、Windows、IOS、Android
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <h1 id="MYLF2" style="font-size:28px;">
            ANKI探索版和ANKI官方版有什么区别？
          </h1>
          <p style="font-size:15px;color:#404040;">
            ANKI探索版由国内的团队基于开源项目AnkiAndroid开发，从界面、易用性、学习资源多方面优化ANKI
          </p>
          <p style="font-size:15px;color:#404040;">
            安装ANKI志愿者版后就会获取ANKI已有的卡牌数据，有问题欢迎大家反馈
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            Anki志愿者版GitHub地址：<a href="https://github.com/ankichinateam"
                                target="_blank">https://github.com/ankichinateam</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            备用版：V2.9.6 <a href="https://jwmc3ydpuq.feishu.cn/file/boxcnano0aC1jqNhrW2Xierygqd" target="_blank">https://jwmc3ydpuq.feishu.cn/file/boxcnano0aC1jqNhrW2Xierygqd</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <h1 id="otiNb" style="font-size:28px;">
            数据互通问题
          </h1>
          <ol style="font-size:15px;color:#404040;">
            <li>
              卡牌互通：ANKI志愿者版和ANKI官方版的卡牌文件是互通的，即从ANKI官方版可以把卡牌导入到ANKI志愿者版
            </li>
            <li>
              账号问题：ANKI志愿者版支持手机号（ANKI志愿者版账号）和邮箱登录（官方ANKI账号），如果你想用官方ANKI电脑版+ANKI志愿者版本的组合，就需要都用邮箱登录。如果使用手机号登录，就需要手机版和电脑版都使用ANKI志愿者版本
            </li>
            <li>
              安卓端：安装ANKI志愿者版后就会获取官方ANKI已有的卡牌数据
            </li>
          </ol>
          <h1 id="gMpbQ" style="font-size:28px;">
            <br/>
          </h1>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            <strong>ANKI的安装是简单的，无需多说，不过有一点账号注册问题需要注意下。</strong>
          </p>
          <p style="font-size:15px;color:#404040;">
            如果使用ANKI志愿者版，直接使用手机号验证码登录即可
          </p>
          <p style="font-size:15px;color:#404040;">
            但如果是使用ANKI官方版，同步数据时是需要打开ANKIWEB去注册一个账号的，输入邮箱密码后申请注册后，会给你邮箱发送一个验证信息，需要你打开邮箱去确认。
          </p>
          <p style="font-size:15px;color:#404040;">
            <br/>
          </p>
          <p style="font-size:15px;color:#404040;">
            下载ANKI志愿者版：<a href="https://www.ankichinas.cn/" target="_blank">https://www.ankichinas.cn/</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            Anki中文资源网：<a href="https://file.ankichinas.cn" target="_blank">https://file.ankichinas.cn</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            我的知乎主页：<a href="https://www.zhihu.com/people/woshikakali" target="_blank">https://www.zhihu.com/people/woshikakali</a>
          </p>
          <p style="font-size:15px;color:#404040;">
            ANKI微信交流：加我微信，我拉你进群
          </p>
          <ol style="font-size:15px;color:#404040;">
            <li>
              扫一扫下图
            </li>
            <li>
              或输入微信号添加：woshikakali
            </li>
          </ol>
          <div>
            <img src="/static/images/wxqrcode.png" width="300">
          </div>
        </div>
      </div>
    </div>
    <p>
      <br/>
    </p>
  </div>
</template>

<script>
  export default {
    name: "download"
  }
</script>

<style scoped>

</style>
