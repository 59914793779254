<template>
    <div>
        <div class="text-align-center pd-l-30 pd-r-30 pd-t-100" v-if="success">
            <h3>注销成功</h3>
            <p class="fs-14">请点击客户端设置页->右上角退出，完成账号退出，避免出现异常</p>
        </div>
        <div v-else>
            <div class="text-align-center pd-30">
                <h3>注销账号</h3>
                <p class="fs-14">请谨慎进行此操作，账号注销后，您的云空间资料和账号信息将被永久删除。</p>
            </div>
            <van-field label="手机号" v-model="phone" placeholder="请输入手机号"></van-field>
            <van-field v-model="sms" center clearable label="验证码" placeholder="请输入短信验证码">
                <template #button>
                    <van-button style="min-width: 100px" size="small" :type="btnMsg === '发送验证码'?'info':'default'"
                                @click="sendSMS">{{btnMsg}}
                    </van-button>
                </template>
            </van-field>
            <div class="pd-20">
                <van-button class="w-fill" type="info" @click="onClick">注销</van-button>
            </div>
        </div>
    </div>
</template>

<script>

  import request from '@/libs/api.request'

  export default {
    name: "Cancellation",
    data() {
      return {
        sms: "",
        phone: "",
        btnMsg: "发送验证码",
        key: '',
        success: false
      }
    },
    methods: {
      onClick() {
        if (this.sms.trim() === "") {
          this.$toast('请输入验证码');
          return;
        }
        if (this.key === "") {
          this.$toast('请重新获取验证码');
          return;
        }
        request.request({
          url: "users/destroy",
          method: "POST",
          params: {phone: this.phone, sms: this.sms, key: this.key}
        }).then(() => {
          this.success = true;
        });
      },
      sendSMS() {
        if (this.btnMsg !== "发送验证码") {
          return;
        }
        if (this.phone.trim() === "") {
          this.$toast('请输入手机号');
          return;
        }
        this.$toast('验证码已发送');
        let timer = 60;
        let interval = setInterval(() => {
          if (timer > 0) {
            timer--;
            this.btnMsg = timer + "s";
          } else {
            this.btnMsg = "发送验证码"
            clearInterval(interval)
          }
        }, 1000);
        request.request({
          url: 'verification-codes',
          method: 'POST',
          data: {phone: this.phone}
        }).then((data) => {
          this.key = data.key;
        });
      }
    },
    mounted() {
      request.request({url: 'test/test'});
    }
  }
</script>

<style scoped>

</style>
