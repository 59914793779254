import axios from 'axios'

axios.defaults.timeout = 30000;

class HttpRequest {

  constructor() {
    this.baseUrl = "https://www.ankichinas.com/api/v1/";
    this.onRequest = false;
  }

  getInsideConfig() {
    return {
      baseURL: this.baseUrl,
      headers: {
        //
      }
    }
  }

  requestOver() {
    this.onRequest = false;
    // hideLoading();
  }

  interceptors(instance) {
    this.onRequest = true;
    setTimeout(() => {
      if (this.onRequest) {
        // console.log('>>>>> showLoading')
        // showLoading();
      }
    }, 800);
    // 请求拦截
    instance.interceptors.request.use(config => {
      let add = {};
      if (config.method === 'post') {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        if (!config.data) {
          config.data = {};
        }
        config.data = Object.assign(config.data, add);
      } else {
        if (!config.params) {
          config.params = {};
        }

        config.params = Object.assign(config.params, add);
      }

      return config
    }, error => {
      this.requestOver();
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      this.requestOver();
      if (res.data) {
        const {data, status_code, message} = res.data;
        if (status_code === 0) {
          return Promise.resolve(data)
        }

        return Promise.reject(message)
      }

      return Promise.reject({})
    }, (error) => {
      this.requestOver();
      console.log(error);
      return Promise.reject("网络不稳定，请重试~")
    })
  }

  request(options) {
    const instance = axios.create({
      transformRequest: [function (data) {
        // 将数据转换为表单数据
        let ret = ''
        for (let it in data) {
          ret += '&' + encodeURIComponent(it) + '=' + encodeURIComponent(data[it])
        }
        return ret
      }]
    })
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance);
    return instance(options)
  }
}

export default HttpRequest
