<template>
  <div></div>
</template>

<script>
  export default {
    name: "index",
    mounted() {
      location.replace('https://file.ankichinas.cn')
    }
  }
</script>

<style scoped>

</style>
